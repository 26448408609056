@import "./breakpoints";

// Small devices - 576px
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices - 768px
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices - 992px
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices - 1200px
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}
