@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

.post__media-gallery {
  margin: 0 auto $large;
  direction: ltr;
  @include sm {
    max-width: 80%;
  }

  .post__media-caption {
    @include typewriter5;
    margin-top: $small;
    text-align: center;
  }
}
