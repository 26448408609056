@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

#click-reciever {
  display: block;
  width: 40px;
  height: 44px;
  position: absolute;
  top: 28px;
  left: 42px;

  @include md {
    display: none;
  }

  &:lang(fa) {
    left: unset;
    right: 42px;
  }

  &:checked {
    &:not(:lang(fa)) {
      left: 56px;
    }
    &:lang(fa) {
      right: 62px;
    }
  }

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 3; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

.hamburger-wrapper {
  background-color: transparent;
  width: fit-content;
  padding: $xxSmall;
  border-radius: 4px;
  transition-property: all;
  @include md {
    display: none;
  }
  &[data-aos="hamburger-color-animation"] {
    &.aos-animate {
      background-color: #d94f5c;
      .hamburger {
        background-color: #e0d9cc;
      }
    }
  }
  .hamburger {
    display: block;
    width: 33px;
    height: 4px;
    position: relative;
    border-radius: 3px;
    z-index: 2;
    background-color: #d94f5c;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      margin: 5px 0;
      transform-origin: 0% 100%;
    }
  }
}

// Transform all the slices of hamburger into a crossmark.

#click-reciever:checked ~ .hamburger-wrapper {
  &:not(:lang(fa)) {
    margin: 0 0 0 $medium;
  }

  &:lang(fa) {
    margin: 0 $medium 0 0;
  }
}

#click-reciever:checked ~ .hamburger-wrapper .hamburger {
  background-color: #d94f5c;
}

#click-reciever:checked ~ .hamburger-wrapper .hamburger:nth-last-child(3) {
  opacity: 1;
  transform: rotate(45deg) translate(0px, -4px);
}

#click-reciever:checked ~ .hamburger-wrapper .hamburger:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#click-reciever:checked ~ .hamburger-wrapper .hamburger:nth-last-child(1) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

#click-reciever:checked ~ ul {
  &:not(:lang(fa)) {
    left: 0;
  }

  &:lang(fa) {
    right: 0;
  }
}
