@font-face {
  font-family: tahrirFont;
  src: url("./Far_MashinTahrir.ttf");
}

@font-face {
  font-family: tahrirFontBold;
  src: url("./Far_MashinTahrir.ttf");
  font-weight: bold;
}

@font-face {
  font-family: hilan;
  src: url("./DigiـHilan.ttf");
}

/*************************************/

@mixin typewriterItalic {
  font-family: dico-typewriter, mono;
  font-weight: 400;
  font-style: italic;
}

@mixin typewriterNormal {
  font-family: dico-typewriter, mono;
}

@mixin typewriterScript {
  font-family: dico-typewriter-script, mono;
  font-weight: 400;
  font-style: normal;
}

/*************************************/

@mixin hilan($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa),
  &:not(:lang(fa)) {
    font-family: hilan;
  }

  font-style: $fontStyle;
  font-weight: $fontWeight;
  word-spacing: 6px;
}

@mixin typewriter($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa) {
    font-family: tahrirFont;
  }

  &:not(:lang(fa)) {
    font-family: dico-typewriter, mono;
  }

  font-style: $fontStyle;
  font-weight: $fontWeight;
}

/*************************************/

@mixin typewriter1($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 48px;
    line-height: 48px;
  }

  &:not(:lang(fa)) {
    @include typewriter(normal, bold);
    font-size: 28px;
    line-height: 28px;
  }
}

@mixin typewriter2($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 32px;
    line-height: 32px;
  }

  &:not(:lang(fa)) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 22px;
    line-height: 22px;
  }
}

@mixin typewriter3($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 28px;
    line-height: 28px;
  }

  &:not(:lang(fa)) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 18px;
    line-height: 26px;
  }
}

@mixin typewriter4($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa) {
    @include typewriter();
    font-size: 24px;
    line-height: 24px;
  }

  &:not(:lang(fa)) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin typewriter5($fontStyle: normal, $fontWeight: 400) {
  &:lang(fa) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 20px;
    line-height: 20px;
  }

  &:not(:lang(fa)) {
    @include typewriter($fontStyle, $fontWeight);
    font-size: 12px;
    line-height: 12px;
  }
}

/*************************************/

@mixin hilan1() {
  @include hilan();
  font-size: 32px;
}

@mixin hilan2() {
  @include hilan();
  font-size: 24px;
}

@mixin hilan3() {
  @include hilan();
  font-size: 20px;
}
