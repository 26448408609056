@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

$paper-color: #f1ede9;
$paper-line: rgba(148, 172, 212, 0.6);

.cm__form {
  max-width: 900px;
  background: #f1ede9;
  box-shadow: 10px 10px 40px rgba(black, 0.15);
  border-radius: 4px;
  overflow: hidden;
  margin: auto;
  padding: $large $huge;
  display: flex;
  flex-direction: column;

  .cm__author--label {
    @include typewriter4;
    margin-bottom: $medium;
    padding: 0 $xxSmall;
    display: flex;
    align-items: flex-end;
    .cm--author__input {
      @include hilan3;
      width: 100%;
      max-width: 150px;
      background: transparent;
      border-bottom: 1px solid gray;
      border-radius: 0;
      margin: 0 $xSmall;
      padding: $xxSmall;
      text-indent: $xSmall;
      @include sm {
        width: unset;
        max-width: unset;
      }

      &:lang(fa) {
        line-height: 34px;
      }
    }
  }
  .cm--message__captcha {
    margin-top: $small;
    display: flex;
    justify-content: flex-end;
  }
  .cm--message__input {
    @include hilan3;
    border-radius: 4px;
    min-height: 150px;
    resize: vertical;
    padding: $small $large;
    border: solid 1px gray;
    background: transparent;
  }

  .cm--message__submit {
    @include typewriter4(normal, bold);
    transition: background-color 0.5s;
    width: 100px;
    align-self: flex-end;
    height: 40px;
    margin-top: $small;
    border-radius: 4px;
    background-color: lightgray;
    cursor: not-allowed;

    &:enabled {
      color: white;
      background-color: #d94f5c;
      cursor: pointer;
      &:hover {
        background-color: #8c1520;
      }
    }
    &:lang(fa) {
      padding-top: $xxxSmall;
    }
  }
}

.cm__header {
  @include typewriter2;
  width: 100%;
  height: 50px;
  background: #333;
  border-radius: 5px 5px 0 0;
  color: lightgray;
  display: flex;
  align-items: center;
  padding: 0 $large;
  max-width: 900px;
  margin: $huge auto 0;
}

.comments__wrapper {
  max-width: 900px;
  box-shadow: 10px 10px 40px rgba(black, 0.15);
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin: $huge auto 0;
}

.comments__top {
  @include typewriter2;
  width: 100%;
  height: 50px;
  background: #333;
  border-radius: 5px 5px 0 0;
  color: lightgray;
  display: flex;
  align-items: center;
  padding: 0 $large;
}

.comment {
  width: 100%;
  height: 100%;
  min-height: 125px;
  padding: 0 $large $tiny;
  background: repeating-linear-gradient(
    $paper-color,
    $paper-color 31px,
    $paper-line 31px,
    $paper-line 32px
  );
  line-height: 32px;
  outline: 0;

  .comment__wrapper {
    margin-bottom: $xLarge;

    .comment__header {
      display: flex;
      .comment__icon {
        width: 25px;
        height: 25px;
        align-self: center;
      }

      .comment__author {
        @include hilan3;
        display: flex;
        padding: $xSmall $small 0;
        .comment__date {
          @include hilan3;
          margin: 0 $medium;
          word-spacing: 8px;
        }
      }
    }
    .comment__text {
      @include hilan3;
      text-align: justify;
      &.rtl-comment {
        direction: rtl;
      }

      &.ltr-comment {
        direction: ltr;
      }
    }
  }
}
