@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

.post-container {
  padding: $huge;

  .post__title {
    @include typewriter1;
    margin-bottom: $medium;
    color: #375195;
    &:lang(fa) {
      font-size: 64px;
    }
  }

  .post__subtitle {
    @include typewriter2(normal, bold);
    margin-bottom: $medium;
  }

  .post__paragraph--base {
    @include typewriter3;
    margin-bottom: $medium;
  }

  .post__paragraph {
    @extend .post__paragraph--base;
    &:lang(fa) {
      text-align: justify;
    }

    &--center {
      @extend .post__paragraph--base;
      text-align: center;
    }
  }

  .post_iframe {
    display: flex;
    justify-content: center;
  }

  .post__link {
    position: relative;
    color: #375195;
    text-decoration: none;
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 1px;
      border-width: 0 0 1px;
      border-style: dotted;
    }
  }

  .postscript {
    color: #375195;
    cursor: pointer;
  }

  .post_postscripts {
    border-top: 2px dotted;
    padding-top: $medium;
    @include typewriter5;
    a.postscript {
      &:lang(fa) {
        margin-left: $xxSmall;
      }
      &:not(:lang(fa)) {
        margin-right: $xxSmall;
      }
    }
    p {
      display: flex;
      margin-bottom: $xxSmall;
    }
  }

  .post__paper {
    @include sm {
      background: #fafafa;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3),
        0 0 300px 25px rgba(222, 198, 122, 0.7) inset;
      padding: $xxxHuge $huge;
      margin: 0 auto;
      position: relative;
      max-width: 900px;
      z-index: 0;

      &:before,
      &:after {
        content: "";
        background: #fafafa;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2),
          inset 0 0 300px rgba(222, 198, 122, 0.7);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -2;
        transition: 0.5s;
      }

      &:before {
        left: 0;
        top: 2px;
        transform: rotate(-1deg);
      }

      &:after {
        right: -3px;
        top: 0px;
      }
    }
    @include md {
      padding: $xxxHuge;
    }
  }

  .go-home {
    @include typewriter4;
    display: inline-block;
    margin-bottom: $xLarge;
    color: #d94f5c;
    border: solid 2px #d94f5c;
    padding: $xxSmall $medium;
    transition: background-color 0.5s;
    text-decoration: none;

    mask-image: url("../images/grunge.png");
    mask-size: 944px 604px;
    mask-position: 0rem 5rem;

    &:hover {
      background-color: #d94f5c;
      color: white;
      mask-border-source: url("../images/grunge.png");
      mask-image: none;
    }
  }
}
