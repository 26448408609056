@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

.header__wrapper {
  .header__content {
    direction: ltr;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: $huge;
    filter: saturate(0.5);

    @include lg {
      max-width: 1600px;
      flex-direction: row;
      margin: $huge auto;
    }

    .header {
      color: #375195;
      text-align: center;
      margin-bottom: $small;

      @include lg {
        &:lang(fa) {
          text-align: end;
        }

        &:not(:lang(fa)) {
          text-align: start;
        }
      }
      .header__text {
        @include typewriter1;

        &:first-child {
          margin-bottom: $large;
        }
      }
      .subheader__text {
        @include typewriter3;

        &:first-child {
          margin-bottom: $large;
        }
      }
    }
  }

  .header__separator {
    margin: auto;
  }
}
