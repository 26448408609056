$tiny: 2px;
$xxxxSmall: 3px;
$xxxSmall: 4px;
$xxSmall: 6px;
$xSmall: 8px;
$small: 12px;
$medium: 16px;
$large: 20px;
$xLarge: 24px;
$xxLarge: 28px;
$xxxLarge: 32px;
$huge: 40px;
$xHuge: 48px;
$xxHuge: 56px;
$xxxHuge: 64px;
$giant: 80px;
$xGiant: 96px;
$xxGiant: 112px;
$xxxGiant: 128px;
