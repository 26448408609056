@import "../constants/mixins";
@import "../constants/spaces";

.language-switch {
  position: absolute;
  left: $large;
  top: $xxxLarge;

  &:not(:lang(fa)) {
    right: $large;
    left: unset;
  }

  @include md {
    top: $huge;
    &:lang(fa),
    &:lang(en) {
      left: $large;
      right: unset;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;

    input {
      opacity: 0;
      width: 70px;
      height: 34px;
      z-index: 2;
      position: absolute;
      cursor: pointer;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    direction: rtl;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    background-color: #375195;
    color: white;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    padding-right: $small;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: $xxxSmall;
      bottom: $xxxSmall;
      background-color: white;
      text-align: left;
      border-radius: 50%;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    direction: ltr;
    padding-left: $small;
    padding-right: 0;
    &:before {
      transform: translateX(35px);
    }
  }
}
