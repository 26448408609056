@import "./constants/fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  @include typewriter;
}

button {
  cursor: pointer;
  background: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:lang(fa) {
    direction: rtl;
  }

  &:not(:lang(fa)) {
    direction: ltr;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body input,
.body button {
  margin-left: 10px;
  height: 25px;
}

.footer {
  margin-left: 100px;
}

.footer button {
  margin-right: 10px;
}
