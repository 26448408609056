@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar .search-wrapper {
  position: relative;
  max-width: 100%;
}

.toolbar input[type="search"] {
  @include typewriter3(normal, bold);
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
  padding: $xxSmall;
  background: transparent;
  max-width: 100%;
  width: 90%;
  @include sm {
    width: unset;
  }
  &::placeholder {
    color: gray;
    opacity: 0.8;
  }
}

.toolbar input[type="range"] {
  display: none;

  @include lg {
    display: unset;
  }
}

.toolbar .counter {
  @include typewriter4;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  color: gray;

  &:lang(fa) {
    right: 0;
  }
}

.view-options {
  display: flex;
  align-items: center;
}

.view-options li:not(:last-child) {
  @include md {
    margin-right: $large;
  }
}

.view-options button {
  padding: $tiny;
  border: 3px solid transparent;
}

.view-options .active button {
  border-color: #375195;
}

.zoom {
  display: none;
  @include md {
    display: flex;
  }
}
