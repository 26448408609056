@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

.construction__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(-25deg);
  color: #d94f5c;
  border: solid 6px #d94f5c;
  width: 280px;
  margin: auto;
  height: 120px;

  &:lang(fa) {
    h2 {
      @include typewriter1;
    }

    h3 {
      @include typewriter2;
    }
  }

  &:not(:lang(fa)) {
    h2 {
      @include typewriter2;
    }

    h3 {
      @include typewriter3;
    }
  }
}
