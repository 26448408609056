@import "aos/dist/aos.css";
@import "./constants/mixins";
@import "./constants/spaces";
@import "./constants/fonts";

ol,
ul {
  list-style: none;
}

a {
  color: inherit;
}

.app {
  background: linear-gradient(
      0deg,
      rgba(250, 250, 250, 0.7),
      rgba(250, 250, 250, 0.7)
    ),
    url("./images/main-background.jpeg");
  background-size: contain;
  background-position: 50% 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2),
    inset 0 0 300px rgba(222, 198, 122, 0.4);
  min-height: 100vh;
  padding-bottom: $huge;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page__intro {
  padding: $huge;
  max-width: 1200px;
  margin: auto;
  font-weight: bold;
}
