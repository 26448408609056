@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

:root {
  --minRangeValue: 180px;
}

.gallery-container {
  max-width: 1030px;
  margin: $huge auto;
  padding: 0 $huge;

  @include xl {
    padding: 0;
  }
  .image-list {
    margin: $xHuge 0;
  }

  .grid-view {
    display: grid;
    grid-gap: 2rem;
    @include sm {
      grid-template-columns: repeat(
        auto-fit,
        minmax(var(--minRangeValue), 1fr)
      );
    }
  }
}

.d-none {
  display: none;
}
