@import "../constants/mixins";
@import "../constants/spaces";
@import "../constants/fonts";

.menu-container {
  padding: $xxxLarge $huge 0 $huge;
  position: sticky;
  width: fit-content;
  top: 0;
  z-index: 2;

  .menu__list {
    position: absolute;
    width: 300px;
    margin-top: -93px;
    padding: $xGiant 0 $large 0;
    border-radius: 14px;
    background: #fafafa;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2),
      inset 0 0 300px rgba(222, 198, 122, 0.4);
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    &:not(:lang(fa)) {
      left: -310px;
      &:before {
        left: $huge;
        right: unset;
      }
    }

    &:lang(fa) {
      right: -310px;
      &:before {
        right: $huge;
        left: unset;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      height: 100%;
      width: 1px;
      background: #db4034;
    }

    .menu__item {
      display: block;
      padding: 0;
      @include typewriter4;
      &:not(:last-child) {
        border-bottom: solid thin #00b0d7;
      }
      .menu__link {
        font-weight: bold;
        display: block;
        padding: $small;
        cursor: pointer;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        position: relative;
        display: block;
        text-align: center;
        color: darkslategray;
        transition: color 0.4s;
        text-decoration: none;

        &:hover,
        &:focus {
          outline: none;
          color: #d94f5c;
        }
      }

      &.menu__item--current {
        .menu__link {
          color: #d94f5c;
        }
      }
    }
  }
}

// Medium devices - min 768px
@include md {
  .menu-container {
    position: relative;
    padding: 0;
    padding-top: $medium;
    margin: auto;
    .menu__list {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      width: unset;
      background: unset;
      transition: unset;
      box-shadow: unset;

      &:before {
        display: none;
      }

      &:lang(en),
      &:lang(fa) {
        left: unset;
        right: unset;
      }

      .menu__item {
        display: flex;
        align-items: center;
        margin: $medium 0;
        &:not(:last-child) {
          border-bottom: unset;
        }

        .menu__link {
          margin: 0 $medium;
          color: #929292;
          &::before,
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background: #d94f5c;
            transform: scale3d(0, 1, 1);
            transition: transform 0.2s;
          }

          &::before {
            top: 0;
            transform-origin: 0 50%;
          }

          &::after {
            bottom: 0;
            transform-origin: 100% 50%;
          }
        }
        &.menu__item--current {
          .menu__link::before,
          .menu__link::after {
            transform: scale3d(1, 1, 1);
            transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
            transition-duration: 0.4s;
          }
        }
      }
    }
  }
}
